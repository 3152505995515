import { useState, useEffect } from 'react'

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(undefined)

  useEffect(() => {
    function handleResize () {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // Empty array ensures that effect is only run on mount

  return isMobile
}

export default useMobile
