import { TitleCaps1 } from 'components/Styleguide'

const PageNotFound404 = () => {
  return (
    <div className='page-project'>
      <TitleCaps1>
        page not found
      </TitleCaps1>
    </div>
  )
}

export default PageNotFound404
