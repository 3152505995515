import Drawings2013 from 'pages/Portfolio/Drawings/components/Drawings2013'

const PageDrawings2013 = () => {
  return (
    <div className='page-project'>
      <Drawings2013 />
    </div>
  )
}

export default PageDrawings2013
