import { map } from 'lodash'

import year2012img2 from 'img/data/drawings/2012/drawing-2.png'
import year2012img3 from 'img/data/drawings/2012/drawing-3.png'
import year2012img4 from 'img/data/drawings/2012/drawing-4.png'
import year2012img5 from 'img/data/drawings/2012/drawing-5.png'
import year2012img6 from 'img/data/drawings/2012/drawing-6.png'
import year2012img7 from 'img/data/drawings/2012/drawing-7.png'
import year2012img8 from 'img/data/drawings/2012/drawing-8.png'
import year2012img9 from 'img/data/drawings/2012/drawing-9.png'
import year2012img10 from 'img/data/drawings/2012/drawing-10.png'
import year2012img11 from 'img/data/drawings/2012/drawing-11.png'
import TellMe from '../../../../components/TellMe'

const imgYear2012 = [
  year2012img2,
  year2012img3,
  year2012img4,
  year2012img5,
  year2012img6,
  year2012img7,
  year2012img8,
  year2012img9,
  year2012img10,
  year2012img11
]

const Drawings2012 = () => {
  return (
    <>
      <div className='intro border'>
        <h1 className='title title-3'>
          drawings
        </h1>
      </div>

      <h2 className='title-5' id='drawings-2012'>
        2012
      </h2>
      {
        map(imgYear2012, img => (
          <div className='section' key={img}>
            <div className='inner'>
              <img src={img} alt='' />
            </div>
          </div>
        ))
      }
      <TellMe>
        Let's talk about your brand
      </TellMe>
    </>
  )
}

export default Drawings2012
