import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'

// css
import 'css/index.scss'

// components
import Home from 'pages/Home'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Portfolio from 'pages/Portfolio/Home'
import Uad from 'pages/Portfolio/Uad'
import Patient from 'pages/Portfolio/Patient'
import ContentFactory from 'pages/Portfolio/ContentFactory'
import BonneBox from 'pages/Portfolio/BonneBox'
import Contact from 'pages/Contact'
import Drawings from 'pages/Portfolio/Drawings'
import DrawingsNYCityGuide from 'pages/Portfolio/Drawings/NYCityGuide'
import Drawings2012 from 'pages/Portfolio/Drawings/Drawings2012'
import Drawings2013 from 'pages/Portfolio/Drawings/Drawings2013'
import ModularBrand from 'pages/Portfolio/ModularBrand'
import RiskRoulette from 'pages/Portfolio/RiskRoulette'
import PageNotFound404 from 'pages/PageNotFound404'
import Styleguide from 'pages/Styleguide'
import theme from 'pages/Styleguide/theme'
import ScrollToTop from 'components/ScrollToTop'

const pageTitle = (title = '') => {
  document.title = title + ' | Bérénice K.'
}

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <ThemeProvider theme={theme}>
      <Header />
      <div id='page' className='page-width'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/styleguide' element={<Styleguide />} />
          <Route path='portfolio' exact>
            <Route path='' element={<Portfolio />} />
            <Route path='patient-interactive-video' element={<Patient />} />
            <Route path='content-factory' element={<ContentFactory />} />
            <Route path='risk-roulette' element={<RiskRoulette />} />
            <Route path='usine-a-design' element={<Uad />} />
            <Route path='bonne-box' element={<BonneBox />} />
            <Route path='modular-brand-experience' element={<ModularBrand />} />
          </Route>
          <Route path='contact' element={<Contact />} />
          <Route path='drawings'>
            <Route path='/' element={<Drawings />} />
            <Route path='/ny-city-guide' element={<DrawingsNYCityGuide />} />
            <Route path='/2013' element={<Drawings2013 />} />
            <Route path='/2012' element={<Drawings2012 />} />
          </Route>
          <Route path='*' element={<PageNotFound404 />} />
        </Routes>
      </div>
      <Footer />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('content')
)
export { pageTitle }
