import {
  TitleMain1,
  TitleMain2,
  Text,
  TitleCaps1,
  TitleCaps2,
  TitleCaps3,
  TitleMain3
} from 'components/Styleguide'

const Styleguide = () => {
  return (
    <div className='page-project'>
      <h1>
        Styleguide
      </h1>

      <TitleMain1>
        (TitleMain1) Tempor dolor enim adipisicing aute pariatur quis commodo ipsum irure.
      </TitleMain1>

      <TitleMain2>
        (TitleMain2) Tempor dolor enim adipisicing aute pariatur quis commodo ipsum irure.
      </TitleMain2>

      <TitleMain3>
        (TitleMain3) Tempor dolor enim adipisicing aute pariatur quis commodo ipsum irure.
      </TitleMain3>

      <TitleCaps1>
        (TitleCaps1) Tempor dolor enim adipisicing aute pariatur quis commodo ipsum irure.
      </TitleCaps1>

      <TitleCaps2>
        (TitleCaps2) Tempor dolor enim adipisicing aute pariatur quis commodo ipsum irure.
      </TitleCaps2>

      <TitleCaps3>
        (TitleCaps3) Tempor dolor enim adipisicing aute pariatur quis commodo ipsum irure.
      </TitleCaps3>

      <Text>
        (Text) Labore sint ex consectetur elit dolor sunt aliqua Lorem. Aliqua duis eiusmod qui id reprehenderit non sunt irure. Esse do nostrud magna nulla nisi sit proident ipsum velit dolor eu. Anim Lorem adipisicing occaecat deserunt anim aute velit minim. Laboris et laboris excepteur laboris cupidatat non duis ea nostrud velit pariatur. Ea qui sint excepteur ex ad ex velit. Enim aliqua elit magna pariatur qui culpa culpa ex anim id officia elit.
      </Text>
    </div>
  )
}

export default Styleguide
