import { NavLink } from 'react-router-dom'
import { map } from 'lodash'

// data
import tree from 'tree'

const Footer = () => {
  return (
    <div id='footer'>
      <div className='inside page-width'>
        <div className='column column-1'>
          <div className='section' id='ft-section-1'>
            <h2 className='title'>
              {tree.portfolio.label}
            </h2>
            <ul className='list'>
              {
                map(
                  tree.portfolio.children,
                  child => (
                    <li className='item' key={child.label}>
                      <NavLink to={child.url}>
                        {child.label}
                      </NavLink>
                    </li>
                  ))
                }
            </ul>
          </div>
        </div>
        <div className='column column-2'>
          <div className='section' id='ft-section-2'>
            <h2 className='title'>
              {tree.contact.label}
            </h2>
            <ul className='list'>
              {
                map(tree.contact.children, child => (
                  <li className='item' key={child.label}>
                    {
                      child.isExternal
                        ? (
                          <a href={child.url} target='_blank' rel='noreferrer'>
                            {child.label}
                          </a>
                          )
                        : (
                          <NavLink to={child.url}>
                            {child.label}
                          </NavLink>
                          )
                    }
                  </li>
                ))
              }
            </ul>
          </div>
          <div className='section' id='ft-section-3'>
            <h2 className='title'>
              Drawings
            </h2>
            <ul className='list'>
              {
                map(tree.drawings.children, child => (
                  <li className='item' key={child.label}>
                    <NavLink to={child.url}>
                      {child.label}
                    </NavLink>
                  </li>
                ))
                }
            </ul>
          </div>
        </div>

        {/* credits */}
        <p id='credits'>
          Copyright bérénice krzyzaniak -
          design
          <a href='http://www.berenicek.com' target='_blank' rel='noreferrer'>
            berenicek.com
          </a> -
          développement front :
          <a href='https://twitter.com/kazes' target='_blank' rel='noreferrer'>
            Florian Boudot
          </a>
        </p>
      </div>
    </div>
  )
}

export default Footer
