import { useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import {
  TitleCaps1,
  TitleCaps2,
  TitleCaps3,
  Label,
  TitleMain1,
  Text,
  TitleMain3,
  HighlightYellow,
  HighlightPink,
  Separator,
  Hashtags
} from 'components/Styleguide'
import TellMe from 'components/TellMe'

import tree from 'tree'

// images
import image1 from './img/image1.png'
import imageResultComputer from './img/result-computer.jpg'
import imagePersonalized from './img/personalized.jpg'

// video
// import videoSrc from 'video/risk-roulette-video-edit_V02.mp4'

// svg
import Notepad from 'svg/Notepad'
import Rocket from 'svg/Rocket'
import Bulb from 'svg/Bulb'

// components
import {
  ImgWithBorder,
  DoublePart,
  Part,
  TitleWithLabel,
  GrayBox,
  // BlockPlayer,
  Box
  // PlayerWrapper,
  // Player
} from 'components/common'
import { pageTitle } from 'index'
import ButtonNextProject from 'components/ButtonNextProject'

const project = tree.portfolio.children.patient

// CO-CREATION
// List with icons in discs
const ListDisc = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;  
  }
`

const ListContent = styled.div`
  margin-left: 50px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-left: 0; 
  }
`

const ListText = styled(props => <Text small {...props} />)`
  text-align: left;

  @media (max-width: 768px) {
    text-align: center; 
  }
`

const Disc = styled.div`
  flex-shrink: 0;
  background: white;
  width: 95px;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 95px;

  svg {
    max-height: 40%;
    height: 100%;
  }
`

const ListTitle = styled(TitleCaps1)`
  margin-bottom: 10px;
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
  }
`

// RESULT
const Result = styled.div(({ theme }) => css`
  padding-top: ${theme.space.large}px;
  padding-bottom: ${theme.space.small}px;
`)

const Img2 = styled.div(({ theme }) => css`
    margin-top: ${theme.space.medium}px;
    margin-bottom: ${theme.space.medium}px;
    img {
        width: 100%;
        max-width: calc(1817px / 2);
        display: block;
        margin: 0 auto;
    }
`)

const CreativeDirection = styled.div`
  padding-top: 40px;
  padding-bottom: 70px;
`

// PERSONALIZED
const Personalized = styled.div`
    padding-top: 60px;
    padding-bottom: 20px;
`
const Img3 = styled.div(({ theme }) => css`
    margin-top: ${theme.space.small}px;
    margin-bottom: ${theme.space.medium}px;
    img {
        width: 90%;
        max-width: calc(1721 / 2);
        display: block;
        margin: 0 auto;
    }
`)

const Patient = () => {
  useEffect(() => {
    pageTitle(project.label)
  }, [])

  return (
    <div className='page-project'>
      <TitleCaps1 mb={20}>
        as creative director for aptus health, healthcare agency
      </TitleCaps1>

      <TitleWithLabel>
        <Label>
          Case study
        </Label>
        <TitleMain1>
          {project.label}
        </TitleMain1>
      </TitleWithLabel>
      <Hashtags>
        #digital #experience #video #branded #multichannel #campaign #strategy #creativedirection #UI #UX #interaction #gamification
      </Hashtags>

      <ImgWithBorder distance='11.5%' width={868}>
        <img src={image1} alt='' />
      </ImgWithBorder>

      <DoublePart>
        <Part>
          <TitleCaps2 mb={10}>
            the context
          </TitleCaps2>
          <Text small>
            Uncontrolled asthma is an underestimated challenge with significant consequences. Spotting the signs and prescribing an add-on therapy can potentially improve patients’ quality of life.
          </Text>
        </Part>
        <Part>
          <TitleCaps2 mb={10}>
            the audience
          </TitleCaps2>
          <Text small>
            General practitioners and respiratory specialists.<br />
            A specific journey will be designed depending on their position in the adoption ladder.
          </Text>
        </Part>
      </DoublePart>

      <Separator />

      {/* CHALLENGE */}
      <Box mt={40} mb={40}>
        <TitleCaps3>
          the challenge
        </TitleCaps3>
        <TitleMain3>
          How do we create a dynamic interactive experience to raise physician’s awareness on the signs of uncontrolled asthma ?
        </TitleMain3>
      </Box>

      <Separator />

      {/* CO-CREATION */}
      <GrayBox>
        <TitleCaps2 mb={10}>
          a co-creation approach
        </TitleCaps2>
        <Text mb={60} small>
          All steps of the project’s creation were completed in collaboration with global, local & MLR teams to make sure the final product would correspond to each countries’ needs while pushing the boundaries of healthcare e-learning experiences.
        </Text>

        <ListDisc>
          <Disc>
            <Bulb />
          </Disc>
          <ListContent>
            <ListTitle>
              How do you answer both HCP & client needs?
            </ListTitle>
            <ListText>
              After thorough <HighlightPink>benchmark & user research</HighlightPink>, we reunited global, local & MLR teams for an <HighlightYellow>ideation workshop</HighlightYellow>. We reflected on doctor’s needs during a consultation with a series of personas. It resulted in the choice of a <HighlightYellow>virtual consultation tool</HighlightYellow>.
            </ListText>
          </ListContent>
        </ListDisc>
        <ListDisc>
          <Disc>
            <Notepad />
          </Disc>
          <ListContent>
            <ListTitle>
              how staying focused on users keeps stakeholders engaged
            </ListTitle>
            <ListText>
              To prove our concept, we consolidated our approach by <HighlightYellow>researching experiences from video game industry</HighlightYellow> and writing scenarios that fit MLR specs for each personna. <HighlightPink>UX tests and physicians’ interviews</HighlightPink> endorsed the <HighlightYellow>choice of real life patient videos</HighlightYellow> and raised a need for printable take-away documents.
            </ListText>
          </ListContent>
        </ListDisc>
        <ListDisc>
          <Disc>
            <Rocket />
          </Disc>
          <ListContent>
            <ListTitle>
              leading to an outstanding digital experience
            </ListTitle>
            <ListText>
              Working <HighlightPink>closely with our developers’ team</HighlightPink>, we began our production phase by signing-off our UK master script for shooting while consolidating the technical specifications. Our director assembled a film crew & cast actors for each persona to bring our consultations to life. We <HighlightYellow>optimized production costs</HighlightYellow> by constantly reviewing the user journey to mix video with text content and algorithm charts.
            </ListText>
          </ListContent>
        </ListDisc>
      </GrayBox>

      <Separator />

      <Result>
        <TitleCaps2 mb={10}>
          The result
        </TitleCaps2>
        <Text small>
          The patient interactive video is a quick and self-directed experience engaging HCP’s to conduct 3 asthma consultations and uncover the signs of uncontrolled asthma. Our dynamic experience re-created a real-life patient consultation with access to data from the patient’s history to test results and recommanded treatment algorithms mixed with interactions with our live patient. This experience was developped in 3 languages for the UK, Spain and Germany.
        </Text>
        <Img2 width={977}>
          <img src={imageResultComputer} alt='' />
        </Img2>
      </Result>

      <Separator />

      {/* CREATIVE DIRECTION */}
      <CreativeDirection>
        <TitleCaps3>
          creative direction - making every digital journey memorable
        </TitleCaps3>
        <TitleMain3>
          I pitched & inspired this exciting experience to clients, led its execution by bringing together a talented team and keeping them invested in pushing the boundaries of healthcare digital communication.
        </TitleMain3>
      </CreativeDirection>

      <Separator />

      {/* VIDEO */}
      {/* <BlockPlayer>
        <PlayerWrapper>
          <Player
            url={videoSrc}
            controls
            volume={0.5}
            width='100%'
            height='100%'
          />
        </PlayerWrapper>
      </BlockPlayer>

      <Separator /> */}

      {/* A PERSONALIZED JOURNEY */}
      <Personalized>
        <TitleCaps2 mb={10}>
          A personalized journey
        </TitleCaps2>
        <Text small>
          To drive healthcare professionals to our eDetail, we created dynamic drivers. Through this drivers, we engaged physicians offering them a chance to learn more about our patients struggle and allowing them to download a take-away from the experience and apply it to their practice.
        </Text>
        <Img3>
          <img src={imagePersonalized} alt='' />
        </Img3>
      </Personalized>

      <Separator />

      <ButtonNextProject project={project} />

      <TellMe>
        Let's talk about your brand
      </TellMe>
    </div>
  )
}

export default Patient
