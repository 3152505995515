import imgLogo from 'img/data/projects/bonnebox/logo-bonne-box.png'
import imgHomepageOrdi from 'img/data/projects/bonnebox/homepage-ordi.png'
import imgHomepage from 'img/data/projects/bonnebox/homepage.jpg'
import imgChartePhoto from 'img/data/projects/bonnebox/charte-photo.png'
import imgCharteThumbs from 'img/data/projects/bonnebox/charte-thumbs.png'
import imgAbonnement from 'img/data/projects/bonnebox/abonnement.png'
import imgShootings from 'img/data/projects/bonnebox/shootings.jpg'
import imgPageProduit from 'img/data/projects/bonnebox/page-produit.jpg'
import TellMe from 'components/TellMe'
import { useEffect } from 'react'
import { pageTitle } from 'index'
import tree from 'tree'

const project = tree.portfolio.children.bonnebox

const BonneBox = () => {
  useEffect(() => {
    pageTitle(project.label)
  }, [])
  return (
    <div className='page-project'>
      {/* INTRO */}
      <div className='intro'>
        <h1 className='title title-3'>
          Refonte de l’identité web de la Bonne Box
        </h1>

        <img src={imgLogo} alt='La Bonne Box' className='logo' />
      </div>

      {/* CHALLENGE */}
      <div className='challenge'>
        <p className='title title-3'>
          <strong>Le défi</strong>
        </p>
        <p className='text title-3'>
          renouveler l’univers de la bonne box qui propose une sélection d’épicerie fine chaque mois.<br />
          humaniser la box et donner envie au visiteur de s’abonner.<br />
          réfléchir à une nouvelle ergonomie de présentation de l’abonnement et de la box cadeau.<br />
          direction artistique réfléchie en équipe avec virginie duhamel barnay.
        </p>
      </div>

      {/* STRATEGIE */}

      <div className='section'>
        <div className='inner '>
          <h2 className='keyword title-4'>stratégie</h2>

          <h3 className='description title-2'>Conseil stratégique &amp;  refonte de l’identité web d’une Box d’épicerie fine pour épicurieux.</h3>

          <img src={imgHomepageOrdi} alt='Conseil stratégique &amp;  refonte de l’identité web d’une Box d’épicerie fine pour épicurieux.' />
        </div>
      </div>
      {/* MES DÉMARCHES */}
      <div className='process'>
        <div className='title title-3'>Nos démarches</div>
        <ol>
          <li>
            Définir une identité plus en adéquation avec la cible<br />
            épicurienne et la sélection de produits haut de gamme<br />
            en introduisant une dimension humaine<br />
            et l’idée d’un bon moment partagé.
          </li>
          <li>
            Mettre en valeur la sélection d’épicerie fine et<br />
            upgrader les listings produits grâce à<br />
            des documents de référence pour le shooting des boxes,<br />
            les packshots et les visuels de mise en situation.
          </li>
          <li>
            Conseiller La Bonne Box dans la création de<br />
            nouveaux visuels d’ambiance inspirants et<br />
            en accord avec l’identité définie pour faire vivre un site<br />
            à l’esthétique fortement basée sur la photographie.
          </li>
          <li>
            Optimiser l’expérience d’achat du visiteur<br />
            dans les pages de vente d’abonnement (abonnement,<br />
            offrir...) grâce à une ergonomie simplifiée et<br />
            une meilleure hiérarchie de l’information.
          </li>
          <li>
            Réunir une équipe et diriger le shooting<br />
            de visuels pour créer des visuels représentatifs<br />
            de boxes thématiques.
          </li>
        </ol>
      </div>

      {/* CHARTE */}

      <div className='section'>
        <div className='inner bg-dark'>
          <h2 className='keyword title-4'>chartes</h2>

          <h3 className='description title-2'>Création de documents de référence pour des visuels inspirants et optimisés pour la vente.</h3>

          <img src={imgChartePhoto} alt='Création de documents de référence pour des visuels inspirants et optimisés pour la vente.' />
          <img src={imgCharteThumbs} alt='Création de documents de référence pour des visuels inspirants et optimisés pour la vente.' />
        </div>
      </div>
      {/* HOMEPAGE */}

      <div className='section'>
        <div className='inner '>
          <h2 className='keyword title-4'>homepage</h2>

          <h3 className='description title-2'>Nouvelle maquette web plus axée sur l’envie réalisée en collaboration avec Virginie Barnay Duhamel.</h3>

          <img src={imgHomepage} alt='Nouvelle maquette web plus axée sur l’envie réalisée en collaboration avec Virginie Barnay Duhamel.' />
        </div>
      </div>
      {/* E-COMMERCE */}

      <div className='section'>
        <div className='inner bg-dark'>
          <h2 className='keyword title-4'>e-commerce</h2>

          <h3 className='description title-2'>Création d’une nouvelle ergonomie des espaces de vente sur le site pour une expérience d’achat optimisée.</h3>

          <img src={imgAbonnement} alt='La Bonne Box - Abonnement' />
          <img src={imgPageProduit} alt='La Bonne Box - Page produit' style={{ marginBottom: 40 }} />
        </div>
      </div>
      {/* SHOOTINGS */}

      <div className='section'>
        <div className='inner '>
          <h2 className='keyword title-4'>shootings</h2>

          <h3 className='description title-2'>
            Direction artistique de shootings d’après moodboards. Constitution d’une équipe avec une photographe et une styliste photo.
          </h3>

          <img src={imgShootings} alt='La Bonne Box - shooting' />
        </div>
      </div>

      <TellMe>
        Let's talk about your brand
      </TellMe>
    </div>
  )
}

export default BonneBox
