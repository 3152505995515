import Drawings2012 from 'pages/Portfolio/Drawings/components/Drawings2012'

const PageDrawings2012 = () => {
  return (
    <div className='page-project'>
      <Drawings2012 />
    </div>
  )
}

export default PageDrawings2012
