import imgYear2013 from 'img/data/drawings/2013/drawing-1.png'

import TellMe from '../../../../components/TellMe'

const Drawings2013 = () => {
  return (
    <>
      <div className='intro border'>
        <h1 className='title title-3'>
          drawings
        </h1>
      </div>

      <h2 className='title-5' id='drawings-2013'>
        2013
      </h2>
      <div className='section'>
        <div className='inner'>
          <img src={imgYear2013} alt='' />
        </div>
      </div>
      <TellMe>
        Let's talk about your brand
      </TellMe>
    </>
  )
}

export default Drawings2013
