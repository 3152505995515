import NYCityGuide from 'pages/Portfolio/Drawings/components/NYCityGuide'

const PageNYCityGuide = () => {
  return (
    <div className='page-project'>
      <NYCityGuide />
    </div>
  )
}

export default PageNYCityGuide
