import React, { useEffect } from 'react'
import styled from '@emotion/styled'

// components
import TellMe from 'components/TellMe'
import tree from 'tree'
import { pageTitle } from 'index'
import {
  Hashtags,
  Label,
  Separator,
  Text,
  TitleCaps1,
  TitleCaps2,
  TitleCaps3,
  TitleMain1,
  TitleMain3
} from 'components/Styleguide'
import { Box, GrayBox, Img2, ImgWithBorder, TitleWithLabel } from 'components/common'

// images
import image1 from './img/image1.png'
import imgLogo from './img/uad-logo.png'
import imgHome from './img/uad-home.jpg'
import imgRefined from './img/refined-search.png'
import imgPersonalized from './img/personalized.png'
import imgTypology from './img/typology.png'
import imgImpactfulTools from './img/impactful-tools.jpg'
import imgSeasonal1 from './img/seasonal-1.jpg'
import imgSeasonal2 from './img/seasonal-2.jpg'
import imgSeasonal3 from './img/seasonal-3.jpg'
import ButtonNextProject from 'components/ButtonNextProject'

const project = tree.portfolio.children.uad

const ThreeColumn = styled.div`
  display: grid;
  gap: 30px;
  padding: 0 40px 40px 40px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`
const ThreeImages = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 40px 0;
  div:nth-child(2) {
    margin: 0 20px;    
  }
`

const Uad = () => {
  useEffect(() => {
    pageTitle(project.label)
  }, [])
  return (
    <div className='page-project'>
      <TitleCaps1 mb={20}>As an in-house art director for l’usine à design / E-commerce</TitleCaps1>

      <TitleWithLabel>
        <Label>Experience</Label>
        <TitleMain1>{project.label}</TitleMain1>
      </TitleWithLabel>
      <Hashtags>
        #identity #branding #rebrand #styleguide #UX #UI #design #photoshoots #artdirection
        #newsletters #ecommerce #website
      </Hashtags>

      <ImgWithBorder distance='8.4%' width={868}>
        <img src={image1} alt='' />
      </ImgWithBorder>

      {/* CHALLENGE */}
      <Box mt={40} mb={40}>
        <TitleCaps3>The challenge</TitleCaps3>
        <TitleMain3>
          How do you build a strong brand for a personalized furniture and decoration e-commerce
          website, introducing customers to a new way of customizing their interiors?
        </TitleMain3>
      </Box>

      <Separator />

      <GrayBox pl={20} pr={20}>
        <TitleCaps2 mb={30}>
          Building an inspiring brand
        </TitleCaps2>
        <Img2 width={778} mb={40}>
          <img src={imgLogo} alt='' />
        </Img2>
        <Text mb={40}>
          After a serie of workshop defining our brand platform & our storytelling, we decided to move on with a full re-brand of the identity and website. The new brand image reflected the values of simplicity, friendlyness and warmth of this home interior brand. It also sublty implied personalization as the company’s goal was first to assess its position of a home design leader, with a cherry on top.
        </Text>
      </GrayBox>

      <Separator mb={60} />

      {/* MES DÉMARCHES */}
      <div className='process'>
        <TitleCaps2 mb={10}>
          My approach
        </TitleCaps2>
        <ol>
          <li>
            <Text>
              Define a unique brand image for L’Usine à Design that will enhance the marketing plan
              throughout seasons and trends. Set a creative vision and a consistent storytelling.
            </Text>
          </li>
          <li>
            <Text>
              Imagine a new concept of user experience allowing users to customize their piece of
              furniture (fabric, color, details...) with a tool that adapts to different product
              types.
            </Text>
          </li>
          <li>
            <Text>
              Install a trust relationship with clients through a clear & optimized funnel. Refine
              our UX & UI designs to fit client’s expectations with AB testing methods.
            </Text>
          </li>
          <li>
            <Text>
              Re-think the brand’s identity & logotype to create a more inspiring brand experience
              that will resonate the interior design environment and the new brand platform
            </Text>
          </li>
          <li>
            <Text>
              Lead the design & photo editing team to high quality standards. Build technical
              specifications for the photoshoot of cohesive packshots to optimize the rendering with
              Adobe Scene7.
            </Text>
          </li>
          <li>
            <Text>
              Lead a team to meet e-commerce strong deadlines. Create a design team workflow adapted
              to a start-up fast-paced environnement, merging product & marketing activities.
            </Text>
          </li>
        </ol>
      </div>

      <GrayBox pl={20} pr={20}>
        <TitleCaps2 mb={10}>
          The result
        </TitleCaps2>
        <Text mb={40}>
          The rebrand strongly anchored L’Usine à Design in the home & furniture design landscape. The new website design allowed space to self-promotion and event customization as seen below for the 3 year anniversary of the shop opening.
        </Text>
        <Img2 width={1800} mb={40}>
          <img src={imgHome} alt='' />
        </Img2>
      </GrayBox>

      <Separator />

      {/* CHALLENGE */}
      <Box mt={40} mb={40}>
        <TitleCaps3>
          In-house art director - a direct line to user’s behavior
        </TitleCaps3>
        <TitleMain3>
          Rich of daily feedbacks from customers, I developped an outstanding user experience that simplified furniture personalization with gradual steps while improving its render.
        </TitleMain3>
      </Box>

      <Separator />

      <GrayBox pl={20} pr={20} pb={20}>
        <TitleCaps2 mt={20} mb={60}>
          A new ergonomy
        </TitleCaps2>
        <ThreeColumn>
          <div>
            <Img2 width={513}>
              <img src={imgRefined} alt='' />
            </Img2>
            <TitleCaps3 mt={40} mb={10}>
              Refined search
            </TitleCaps3>
            <Text mb={40}>
              The rebrand strongly anchored L’Usine à Design in the home & furniture design landscape. The new website design allowed space to self-promotion and event customization as seen below for the 3 year anniversary of the shop opening.
            </Text>
          </div>
          <div>
            <Img2 width={513}>
              <img src={imgPersonalized} alt='' />
            </Img2>
            <TitleCaps3 mt={40} mb={10}>
              Personalization
            </TitleCaps3>
            <Text mb={40}>
              The personalization tool was simplified to propose ready-to-buy default color-set-ups  that user could refine by changing specific options in additional tabs.
            </Text>
          </div>
          <div>
            <Img2 width={513}>
              <img src={imgTypology} alt='' />
            </Img2>
            <TitleCaps3 mt={40} mb={10}>
              Product typology
            </TitleCaps3>
            <Text mb={40}>
              By refining a product typology and related secific needs, we deployed several options of selection inside the product pages so custom selection was always the simplest.
            </Text>
          </div>
        </ThreeColumn>
      </GrayBox>

      <Separator />

      <TitleCaps2 mt={60} mb={10}>
        Impactful marketing tools
      </TitleCaps2>
      <Text>
        Design and supervise creation of daily newsletters, automated emails, banner campaigns...
      </Text>
      <ImgWithBorder distance='-0.2%' width={1815}>
        <img src={imgImpactfulTools} alt='' />
      </ImgWithBorder>

      <GrayBox pl={0} pr={0}>
        <Box pl={20} pr={20}>
          <TitleCaps2 mb={30}>
            Seasonal moodboards
          </TitleCaps2>
          <Text mb={40}>
            When every color scheme is possible, it is necessary to define color palettes and moodboards to provide guidance to customers but also to create inspiring listing pages that will place the brand as a trendsetter. Moodboards were therefor created each season in collaboration with the Collection director.
          </Text>
        </Box>

        <ThreeImages>
          <Img2 width={531}>
            <img src={imgSeasonal1} alt='' />
          </Img2>
          <Img2 width={730}>
            <img src={imgSeasonal2} alt='' />
          </Img2>
          <Img2 width={540}>
            <img src={imgSeasonal3} alt='' />
          </Img2>
        </ThreeImages>
      </GrayBox>

      <Separator />

      <ButtonNextProject project={project} />

      <TellMe>Let's talk about your brand</TellMe>
    </div>
  )
}

export default Uad
