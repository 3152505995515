import NYCityGuide from 'pages/Portfolio/Drawings/components/NYCityGuide'
import Drawings2012 from 'pages/Portfolio/Drawings/components/Drawings2012'
import Drawings2013 from 'pages/Portfolio/Drawings/components/Drawings2013'
import TellMe from 'components/TellMe'

function Drawings () {
  return (
    <div className='page-project'>

      <NYCityGuide />

      <Drawings2013 />

      <Drawings2012 />

      <TellMe>
        Let's talk about your brand
      </TellMe>
    </div>
  )
}

export default Drawings
