import { useEffect } from 'react'
import styled from '@emotion/styled'

import tree from 'tree'
import {
  TitleCaps1,
  TitleCaps2,
  TitleCaps3,
  Label,
  TitleMain1,
  Text,
  TitleMain3,
  Separator,
  HighlightPink,
  HighlightYellow,
  Hashtags
} from 'components/Styleguide'
import TellMe from 'components/TellMe'
import ButtonNextProject from 'components/ButtonNextProject'

// images
import image1 from './img/image1.png'
import process from './img/process.png'

// video
import videoSrc from 'video/CIALIS-TOTAL_V012-compressed.mov'

// components
import {
  ImgWithBorder,
  DoublePart,
  Part,
  TitleWithLabel,
  BlockPlayer,
  PlayerWrapper,
  Player,
  Process,
  ProcessText,
  ProcessLayout,
  Img2
} from 'components/common'
import { pageTitle } from 'index'

const project = tree.portfolio.children.modularbrand

// CO-CREATION
const CreativeDirection = styled.div`
  padding-top: 40px;
  padding-bottom: 70px;
`

const ModularBrand = () => {
  useEffect(() => {
    pageTitle(project.label)
  }, [])

  return (
    <div className='page-project'>
      <TitleCaps1 mb={20}>
        as creative director for aptus health, healthcare agency
      </TitleCaps1>

      <TitleWithLabel>
        <Label>
          portfolio
        </Label>
        <TitleMain1>
          {project.label}
        </TitleMain1>
      </TitleWithLabel>
      <Hashtags>
        #digital #health #experience #e-detail #self-directed #branded #multichannel #campaign #UI #design #artdirection
      </Hashtags>

      <ImgWithBorder distance='8.2%' width={1319}>
        <img src={image1} alt='' />
      </ImgWithBorder>

      <DoublePart>
        <Part>
          <TitleCaps2 mb={10}>
            the context
          </TitleCaps2>
          <Text>
            While ED is hard to diagnose and patients are at risk of depression, practitionners might target bladder syndrom patients as it is a common symptom.
          </Text>
        </Part>
        <Part>
          <TitleCaps2 mb={10}>
            the audience
          </TitleCaps2>
          <Text>
            GPs & urologists,
            to provide guidance on conversation starters.
          </Text>
        </Part>
        <Part>
          <TitleCaps2 mb={10}>
            the challenge
          </TitleCaps2>
          <Text>
            How do you create a multi-channel brand experience empowering its message and creating a persistant image for an end-of-patent product ?
          </Text>
        </Part>
      </DoublePart>

      <Separator />

      {/* THE RESULT - VIDEO */}
      <BlockPlayer>
        <TitleCaps2 mb={10}>
          the result
        </TitleCaps2>
        <Text mb={30}>
          Following-up on the brand’s core imagery and empowering it by an <HighlightYellow>empathetic scrollytelling</HighlightYellow>, we imagined <HighlightPink>3 gamified digital experiences</HighlightPink> translating <HighlightPink>data and guidelines on ED into our patient hero related feelings</HighlightPink>, each self-directed eDetail adressing it with a different point of view.
        </Text>

        <PlayerWrapper>
          <Player
            url={videoSrc}
            controls
            volume={0.5}
            width='100%'
            height='100%'
          />
        </PlayerWrapper>
      </BlockPlayer>

      <Separator />

      {/* PROCESS ATOMIZATION */}
      <Process>
        <ProcessLayout>
          <ProcessText>
            <TitleCaps2 mb={30} left>
              modular newsletter system
            </TitleCaps2>

            <Text left>
              Each message of our newsletters was carefully thought with 2 options. The first enlighting numbers and latest guidelines on ED and bladder syndrom treatment, whilst the second was introducing each topic’s stakes for our patient hero.
              <br /><br />
              This modular approach of content allowed countries to empower our brand’s message campaign by selecting modules of message but also the point of view for each of them, making each local campaign closer to local needs.
            </Text>
          </ProcessText>
          <Img2 width={977}>
            <img src={process} alt='' />
          </Img2>
        </ProcessLayout>
      </Process>

      <Separator />

      {/* CREATIVE DIRECTION */}
      <CreativeDirection>
        <TitleCaps3>
          art direction - designing outstanding digital experiences
        </TitleCaps3>
        <TitleMain3>
          I designed each asset of this campaign and overwiewed its localization ensuring quality & consistency accross all countries and compliance to UK regulations.
        </TitleMain3>
      </CreativeDirection>

      <Separator />

      <ButtonNextProject project={project} />

      <TellMe>
        Let's talk about your project
      </TellMe>
    </div>
  )
}

export default ModularBrand
