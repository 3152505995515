import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { miniPortfolio } from 'tree'
import hexToRgba from 'hex-to-rgba'
import { Text, TitleCaps1 } from 'components//Styleguide'

const Disc = styled.div(({ theme }) => css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -30%);
  width: 30%;
  border-radius: 100%;
  background: ${hexToRgba(theme.colors.grayMenu, 0.8)};
  opacity: 0;
  transition: transform 200ms ease, opacity 200ms ease;

  .test:hover & {
    transform: translate(-50%, -50%);
    opacity: 1;
  }

  &:before{
    content: '+';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    font-weight: 100;
    font-family: 'Josefin Sans', sans-serif;
  }

  &:after {
    content: ''; 
    padding-top: 100%;
    display: block;
  }
`)

const MiniPortfolio = () => {
  return (
    <>
      <TitleCaps1 mb={20}>
        Miscellaneous work
      </TitleCaps1>
      <div className='mini-folios-container'>
        {
        miniPortfolio.map(item => {
          return (
            <div className='mini-folio' key={item.label}>
              <NavLink to={item.url} className='test'>
                <div className='pic'>
                  <img src={item.imgSmall} alt={item.label} />
                  <Disc />
                </div>
                <TitleCaps1>
                  {item.labelMiniFolio || item.label}
                </TitleCaps1>
                <Text small>
                  {item.hashtags || item.desc}
                </Text>
              </NavLink>
            </div>
          )
        })
      }
      </div>
    </>
  )
}

export default MiniPortfolio
