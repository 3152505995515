function Rocket (props) {
  return (
    <svg viewBox='0 0 74 73' width={74} height={73} {...props}>
      <path
        fillRule='evenodd'
        fill='#B5F9FF'
        d='M67.447 33.045c-9.584 9.405-17.87 13.477-26.642 17.789l-.264.129v10.854c0 .261-.086.517-.245.725l-7.07 9.28a1.206 1.206 0 01-2.102-.346l-4.57-13.65-11.246-11.199L1.6 42.075a1.199 1.199 0 01-.347-2.094l9.319-7.039a1.21 1.21 0 01.728-.244h10.9l.133-.268c4.328-8.733 8.416-16.983 17.86-26.525C44.776 1.272 54.998.3 62.767.3c4.962 0 8.676.388 8.832.404.565.06 1.01.504 1.071 1.067.103.965 2.44 23.756-5.223 31.274zm2.914-30.043a96.665 96.665 0 00-7.594-.302c-10.33 0-17.738 1.737-20.859 4.89-9.169 9.265-13.174 17.346-17.414 25.902l-.465.939a1.205 1.205 0 01-1.08.667H11.705L4.53 40.519l11.81 3.921c.178.058.339.158.471.289L28.459 56.33c.132.131.232.291.291.468l3.937 11.76 5.444-7.144V50.217c0-.457.26-.873.671-1.075l.936-.461c8.593-4.222 16.71-8.211 26.017-17.344 6.015-5.904 4.962-23.928 4.606-28.335zM51.386 30.299c-4.651 0-8.435-3.769-8.435-8.4s3.784-8.4 8.435-8.4c4.65 0 8.435 3.769 8.435 8.4s-3.785 8.4-8.435 8.4zm0-14.4c-3.322 0-6.025 2.692-6.025 6s2.703 6 6.025 6c3.322 0 6.025-2.692 6.025-6s-2.703-6-6.025-6zM13.725 53.138a1.205 1.205 0 011.471.855 1.198 1.198 0 01-.859 1.466c-.057.015-5.759 1.53-8.252 4.013-1.578 1.571-2.502 6.772-2.813 10.421 3.892-.075 9.454-.691 11.019-2.25 2.024-2.016 3.117-6.975 3.367-8.715a1.21 1.21 0 011.364-1.017 1.2 1.2 0 011.022 1.356c-.041.288-1.041 7.076-4.049 10.073-2.786 2.775-11.36 2.96-13.596 2.96l-.434-.002a1.2 1.2 0 01-1.187-1.261c.053-1.042.602-10.274 3.603-13.262 2.956-2.943 9.085-4.57 9.344-4.637z'
      />
    </svg>
  )
}

export default Rocket
