import { map } from 'lodash'

import nyCityGuide1 from 'img/data/drawings/ny/ny-cityguide-01.jpg'
import nyCityGuide2 from 'img/data/drawings/ny/ny-cityguide-02.jpg'
import nyCityGuide3 from 'img/data/drawings/ny/ny-cityguide-03.jpg'
import nyCityGuide4 from 'img/data/drawings/ny/ny-cityguide-04.jpg'
import nyCityGuide5 from 'img/data/drawings/ny/ny-cityguide-05.jpg'
import nyCityGuide6 from 'img/data/drawings/ny/ny-cityguide-06.jpg'
import nyCityGuide7 from 'img/data/drawings/ny/ny-cityguide-07.jpg'
import nyCityGuide8 from 'img/data/drawings/ny/ny-cityguide-08.jpg'
import TellMe from '../../../../components/TellMe'

const imgNyCityGuide = [
  nyCityGuide1,
  nyCityGuide2,
  nyCityGuide3,
  nyCityGuide4,
  nyCityGuide5,
  nyCityGuide6,
  nyCityGuide7,
  nyCityGuide8
]

const NYCityGuide = () => {
  return (
    <>
      <div className='intro border'>
        <h1 className='title title-3'>
          drawings
        </h1>
      </div>

      <h2 className='title-5' id='ny'>
        my ny city guide
      </h2>

      {
        map(imgNyCityGuide, img => (
          <div className='section' key={img}>
            <div className='inner'>
              <img src={img} alt='' />
            </div>
          </div>
        ))
    }
      <TellMe>
        Let's talk about your brand
      </TellMe>
    </>
  )
}

export default NYCityGuide
