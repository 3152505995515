import React from 'react'
import { NavLink } from 'react-router-dom'

// data
import tree, { social } from 'tree'

// images
import imgSrcLogo from 'img/data/berenicek-logo.png'

const Header = () => {
  // const [scrollPosition, setScrollPosition] = useState(0)

  // const handleScroll = () => {
  //   const position = window.pageYOffset
  //   setScrollPosition(position)
  // }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true })

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // }, [])

  return (
    // <div id='header' className={scrollPosition > 99 ? 'active' : ''}>
    <div id='header' className='active'>
      <div className='page-width'>

        {/* triangles */}
        <div className='triangle' id='triangle-pink' />
        <div className='triangle' id='triangle-blue' />

        {/* logo */}
        <h1 id='logo-berenicek'>
          <NavLink
            to={tree.portfolio.url}
            title={tree.portfolio.label}
          >
            <img src={imgSrcLogo} alt="Bérénice K. - Conception d'expériences &amp; création d'interfaces" />
          </NavLink>
        </h1>

        {/* menu */}
        <div id='menu-container' className='line'>
          <ul className='menu left line'>
            <li id='menu-portfolio' className='item'>
              <NavLink to={tree.portfolio.url} end>
                <span className='text'>
                  {tree.portfolio.label}
                </span>
              </NavLink>
            </li>
            <li id='menu-contact' className='item'>
              <NavLink to={tree.contact.url}>
                <span className='text'>
                  {tree.contact.label}
                </span>
              </NavLink>
            </li>
          </ul>

          <ul className='menu right line'>
            <li id='menu-drawings' className='item'>
              <NavLink to={tree.drawings.url}>
                <span className='text'>
                  {tree.drawings.label}
                </span>
              </NavLink>
            </li>
            <li className='item'>
              <a
                href={social.linkedin.url}
                target='_blank'
                rel='noreferrer'
              >
                <span className='text'>
                  {social.linkedin.label}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Header
