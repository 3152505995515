import { TitleCaps4 } from 'components/Styleguide'
import { Link } from 'react-router-dom'
import tree from 'tree'
import { values } from 'lodash'

const ButtonNextProject = ({ project }) => {
  const projects = values(tree.portfolio.children)
  const currentIndex = projects.findIndex(item => item.label === project.label)
  const nextProject = projects[currentIndex + 1] || projects[0]
  return (
    <TitleCaps4 right mt={20} mb={40}>
      <Link to={nextProject.url} title='next project'>
        {nextProject.label} &gt;
      </Link>
    </TitleCaps4>
  )
}

export default ButtonNextProject
