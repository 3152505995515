import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Box } from 'components/common'

export const TitleMain1 = styled(props => <Box as='h1' {...props} />)`
    font-family: 'kirimomiswash';
    font-size: 60px;
    font-weight: 400;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
    @media (max-width: 768px) {
        font-size: 40px;
        text-align: center;
    }
`
export const TitleMain2 = styled(props => <Box as='h2' {...props} />)`
    font-family: 'kirimomiswash';
    font-size: 50px;
    font-weight: 400;
    line-height: 124%;
    color: ${({ theme }) => theme.colors.black};
    text-align: ${({ left }) => left ? 'left' : 'center'};
    @media (max-width: 768px) {
        font-size: 35px;
        text-align: center;
        line-height: 110%;
    }
`

export const TitleMain3 = styled(props => <Box as='h2' {...props} />)`
    font-family: 'kirimomiswash';
    font-size: 34px;
    font-weight: 400;
    line-height: 135%;
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
    @media (max-width: 768px) {
        font-size: 28px;
    }
`
export const TitleMain4 = styled(props => <Box as='h2' {...props} />)`
    font-family: 'kirimomiswash';
    font-size: 40px;
    font-weight: 400;
    line-height: 120%;
    /* letter-spacing: 2.4px; */
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
    @media (max-width: 768px) {
        font-size: 30px;
    }
`

export const TitleCaps1 = styled(props => <Box as='h3' {...props} />)`
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1.3px;
    color: ${({ theme }) => theme.colors.dark};
    line-height: 130%;
    text-align: ${({ left }) => left ? 'left' : 'center'};
`

export const TitleCaps2 = styled(props => <Box as='h3' {...props} />)`
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1.45px;
    color: ${({ theme }) => theme.colors.dark};
    text-align: ${({ left, right }) => left ? 'left' : right ? 'right' : 'center'};
    small {
        font-size: 16px;
        text-transform: lowercase;
    }
`

export const TitleCaps3 = styled(props => <Box as='h3' {...props} />)`
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
`

export const TitleCaps4 = styled(props => <Box as='h3' {...props} />)`
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colors.dark};
    text-align: ${({ left, right }) => left ? 'left' : right ? 'right' : 'center'};
`

export const Text = styled(props => <Box as='p' {...props} />)`
    font-family: 'Merriweather', serif;
    font-size: ${({ small }) => small ? 12 : 14}px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.dark};
    text-align: ${({ left }) => left ? 'left' : 'center'};
    line-height: ${({ small }) => small ? 160 : 140}%;

    @media (max-width: 768px) {
        line-height: 150%;
    }
`

export const Hashtags = props => <Text small {...props} />

const Highlight = styled.strong`
    font-weight: 400;
    padding: 3px 2px;
`
export const HighlightYellow = styled(Highlight)`
    background: ${({ theme }) => theme.colors.yellow};
`
export const HighlightPink = styled(Highlight)`
    background: ${({ theme }) => theme.colors.pink};
`
export const HighlightBlue = styled(Highlight)`
    background: ${({ theme }) => theme.colors.blue};
`

export const Label = styled(TitleCaps1)(({ theme }) => css`
    display: inline-flex;
    background: ${theme.colors.yellow};
    padding: 10px 22px 5px 22px;
    border-top: 28px solid white;
    margin-right: 20px;
    @media (max-width: 768px) {
        margin-right: 0;
    }
`)

export const Separator = styled(Box)`
    height: 1px;
    background: ${({ theme }) => theme.colors.separator};
`
