function Bulb (props) {
  return (
    <svg viewBox='0 0 50 75' width={50} height={75} {...props}>
      <path
        fillRule='evenodd'
        fill='#B5F9FF'
        d='M37.553 47.256c-1.225 1.113-1.312 3.609-1.241 4.479.002.018-.006.034-.006.053.001.019.011.035.011.054V65.98c0 .066-.005.13-.015.196-.013.086-1.451 8.644-11.503 8.644-10.05 0-11.49-8.558-11.503-8.644a1.294 1.294 0 01-.015-.196V51.842c0-.018.01-.034.011-.053.001-.019-.008-.036-.006-.056.071-.852-.019-3.309-1.252-4.486C4.477 41.562.48 34.148.48 25.795.48 11.891 11.389.58 24.799.58 38.21.58 49.12 11.891 49.12 25.795c0 8.357-4 15.775-11.567 21.461zM24.799 72.26c7.48 0 8.807-5.591 8.958-6.389v-8.228a20.797 20.797 0 01-8.58 1.835c-3.992 0-7.35-1.075-9.336-1.896v8.283c.155.781 1.533 6.395 8.958 6.395zm0-69.12C12.801 3.14 3.04 13.303 3.04 25.795c0 9.537 5.776 15.854 10.621 19.475.021.015.03.038.049.054.019.016.043.021.061.038 2.289 2.146 2.109 5.976 2.07 6.526v2.885c2.236 1.053 10.087 4.124 17.916.007v-2.91c-.049-.649-.23-4.44 2.092-6.527.015-.014.036-.018.052-.031.015-.012.022-.03.038-.042 4.845-3.621 10.621-9.938 10.621-19.475 0-12.492-9.763-22.655-21.761-22.655z'
      />
    </svg>
  )
}

export default Bulb
