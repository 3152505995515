import { useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import tree from 'tree'
import {
  TitleCaps1,
  TitleCaps2,
  TitleCaps3,
  Label,
  TitleMain1,
  Text,
  TitleMain3,
  Separator,
  Hashtags
} from 'components/Styleguide'
import TellMe from 'components/TellMe'

// images
import image1 from './img/image1-small.png'
import approach from './img/approach.png'
import process from './img/process.png'

// video
import videoSrc from 'video/risk-roulette-video-edit_V02.mp4'

// components
import {
  ImgWithBorder,
  DoublePart,
  Part,
  TitleWithLabel,
  GrayBox,
  BlockPlayer,
  Box,
  PlayerWrapper,
  Player,
  Process,
  ProcessText,
  ProcessLayout,
  Img2
} from 'components/common'
import { pageTitle } from 'index'
import ButtonNextProject from 'components/ButtonNextProject'

const project = tree.portfolio.children.riskroulette

// CO-CREATION

const ImgApproach = styled.img`
  width: 100%;
  max-width: calc(1805px / 2);
  display: block;
  margin: 0 auto;
`

const CreativeDirection = styled.div(() => css`
  padding-top: 40px;
  padding-bottom: 70px;
`)

const Intro = styled.div`
  padding-right: 60px;
  padding-left: 60px;
`

const ListNumber = styled.div`
  margin-top: 40px;
  display: grid;
  gap: 50px;
  padding: 0 60px 40px 60px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 768px) {
    display: block;
    padding: 0;
  }
`

const List = styled(Text)`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: row;
    text-align: left;
    margin-bottom: 20px;
  }
`

const Disc = styled.span(({ theme }) => css`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  flex-shrink: 0;
  background: ${theme.colors.yellow};
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    margin-right: 15px;
    margin-bottom: 0;
  }
`)

const RiskRoulette = () => {
  useEffect(() => {
    pageTitle(project.label)
  }, [])

  return (
    <div className='page-project'>
      <TitleCaps1 mb={20}>
        As creative director for aptus health, healthcare agency
      </TitleCaps1>

      <TitleWithLabel>
        <Label>
          Case study
        </Label>
        <TitleMain1>
          {project.label}
        </TitleMain1>
      </TitleWithLabel>
      <Hashtags>
        #digital #experience #e-detail  #branded  #multichannel #campaign #strategy #creativedirection #UI #Artdirection
      </Hashtags>

      <ImgWithBorder distance='8.4%' width={868}>
        <img src={image1} alt='' />
      </ImgWithBorder>

      <DoublePart>
        <Part>
          <TitleCaps2 mb={10}>
            The context
          </TitleCaps2>
          <Text small>
            Pertussis is a major risk for children under 11 months. Vaccinating adult close contacts creates a cocooning effect that protects children at risk.
          </Text>
        </Part>
        <Part>
          <TitleCaps2 mb={10}>
            The audience
          </TitleCaps2>
          <Text small>
            GPs & pediatricians,<br />
            to fulfill their need for clear & easy-to-remember vaccination guidance adapted to their time constraints.
          </Text>
        </Part>
      </DoublePart>

      <Separator />

      {/* CHALLENGE */}
      <Box mt={40} mb={80}>
        <TitleCaps3>
          The challenge
        </TitleCaps3>
        <TitleMain3>
          How do we create an engaging multi-channel campaign to help practitioners identify who to vaccinate and build a stronger link with the brand?
        </TitleMain3>
      </Box>

      <Separator />

      {/* APPROACH */}
      <GrayBox pl={20} pr={20} pt={60}>
        <Intro>
          <TitleCaps2 mb={10}>
            Our approach
          </TitleCaps2>
          <Text mb={40} small>
            The Risk Roulette is a self-directed digital experience with an intuitive ergonomy. The concept is to help HCPs visualize the three target patient groups via a diverse range of short and scannable patient overviews.
          </Text>
        </Intro>

        <ImgApproach src={approach} alt='' />

        <ListNumber>
          <List small>
            <Disc>1</Disc>
            Launch the Risk Roulette.
          </List>
          <List small>
            <Disc>2</Disc>
            The Risk Roulette stops at random showing a patient, their pertussis risk and a call to action.
          </List>
          <List small>
            <Disc>3</Disc>
            The CTA leads to a one-page e-Detail aid of pertussis risk and brand messaging.
          </List>
        </ListNumber>
      </GrayBox>

      <Separator />

      {/* PROCESS ATOMIZATION */}
      <Process mb={100}>
        <ProcessLayout>
          <ProcessText>
            <TitleCaps2 mb={10} left>
              Driving HCP<small>s</small>
            </TitleCaps2>

            <Text left small>
              HCPs engage with the Risk Roulette multichannel campaign via banner drivers, emails and sponsored content on dedicated platforms.
              <br />
              <br />
              All campaign assets were designed to answer GPs' and pediatricians' need for clear content they can immediately put into practice.
            </Text>
          </ProcessText>
          <Img2 width={977}>
            <img src={process} alt='' />
          </Img2>
        </ProcessLayout>
      </Process>

      <Separator />

      {/* CREATIVE DIRECTION */}
      <CreativeDirection>
        <TitleCaps3>
          creative direction - making every digital journey memorable
        </TitleCaps3>
        <TitleMain3>
          When designing an experience for physicians, time is key. The roulette concept generates engagement within seconds, then maximized by a short and impactful messaging.
        </TitleMain3>
      </CreativeDirection>

      <Separator />

      {/* VIDEO */}
      <BlockPlayer>
        <PlayerWrapper>
          <Player
            url={videoSrc}
            controls
            volume={0.5}
            width='100%'
            height='100%'
          />
        </PlayerWrapper>
      </BlockPlayer>

      <Separator />

      <ButtonNextProject project={project} />

      <TellMe>
        Let's talk about your brand
      </TellMe>
    </div>
  )
}

export default RiskRoulette
