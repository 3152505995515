import styled from '@emotion/styled'
import { css } from '@emotion/react'
import ReactPlayer from 'react-player'

const Box = styled.div`
    margin-top: ${({ mt }) => mt || 0}px;
    margin-bottom: ${({ mb }) => mb || 0}px;
    margin-right: ${({ mr }) => mr || 0}px;
    margin-left: ${({ ml }) => ml || 0}px;
    padding-left: ${({ pl }) => pl || 0}px;
    padding-right: ${({ pr }) => pr || 0}px;
    padding-bottom: ${({ pb }) => pb || 0}px;
`

const ImgWithBorder = styled.div(({ theme, distance, width, mb }) => css`
    position: relative;
    margin-top: ${theme.space.medium}px;
    margin-bottom: ${typeof mb !== 'undefined' ? mb : (theme.space.medium + theme.space.xsmall)}px;
    img {
        width: 100%;
        max-width: ${(width || 434) / 2}px;
        display: block;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }
    
    :before {
        content: '';
        position: absolute;
        bottom: ${distance};
        left: 0;
        right: 0;
        height: 1px;
        z-index: 0;
        background: ${theme.colors.separator};
    }
`)

const DoublePart = styled.div(({ theme }) => css`
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: ${theme.space.medium}px;
    padding: ${theme.space.medium}px;
    padding-top: 0;
    padding-bottom: 80px;
    @media (max-width: 768px) {
      display: block;
    }
`)

const Part = styled.div`
    flex-basis: 50%;
    text-align: center;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
`

const TitleWithLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const GrayBox = styled.div(({ theme, pt = 40, pr = 60, pl = 80, pb = 40 }) => css`
  background-color: ${theme.colors.grayBg};
  padding: ${pt}px ${pr}px ${pb}px ${pl || pr}px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
   padding-left: 20px; 
   padding-right: 20px; 
  }
`)

// VIDEO
const BlockPlayer = styled(GrayBox)`
  padding: 40px;
`

const PlayerWrapper = styled.div`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-top: ${({ ratio = '56.25%' }) => ratio};
`
const Player = styled(ReactPlayer)(() => css`
    position: absolute;
    top: 0;
    left: 0;
`)

// PROCESS
const ProcessLayout = styled.div`
  display: flex;
  padding-left: 20px;
  padding-right: 40px;
  flex-direction: row-reverse;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Process = styled(Box)(({ theme }) => css`
  padding-top: ${theme.space.large}px;
`)

const ProcessText = styled.div`
  flex-basis: 36%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 20px;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
    margin-left: 0;
  }
`
const Img2 = styled(Box)(({ width }) => css`
  img {
      width: 100%;
      max-width: calc(${width || 977}px / 2);
      display: block;
      margin: 0 auto;
  }
`)

export {
  ImgWithBorder,
  DoublePart,
  Part,
  TitleWithLabel,
  GrayBox,
  BlockPlayer,
  PlayerWrapper,
  Player,
  Box,
  Process,
  ProcessText,
  ProcessLayout,
  Img2
}
