import styled from '@emotion/styled'
import { omit } from 'lodash'
import { Link } from 'react-router-dom'
import tree from 'tree'

const ButtonContact = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 80px;
`

const LinkStyled = styled(props => props.regularLink ? <a href={props.url} {...omit(props, ['regularLink', 'url'])} /> : <Link to={props.url} {...omit(props, ['regularLink', 'url'])} />)`
  background-color: ${({ theme }) => theme.colors.yellow};
  padding: 17px 20px 13px 20px;
  display: inline-block;
  color: $gray10;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1.9px;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }
  .excla {
    display: inline-block;
    transition:transform 300ms;
  }
  &:hover .excla {
    transform: rotate(360deg);
  }
`

const TellMe = ({ children, url = tree.contact.url, noExcla, regularLink }) => {
  return (
    <ButtonContact>
      <LinkStyled url={url} regularLink={regularLink}>
        {children}
        {!noExcla && <span className='excla'>!</span>}
      </LinkStyled>
    </ButtonContact>
  )
}

export default TellMe
