function Notepad (props) {
  return (
    <svg viewBox='0 0 74 73' width={74} height={73} {...props}>
      <path
        fillRule='evenodd'
        fill='#B5F9FF'
        d='M71.932 12.292c-.009.011-.023.014-.032.024-.011.011-.014.026-.024.036L38.044 45.939c-.004.005-.011.007-.016.012-.059.056-.136.091-.206.136-.067.042-.128.094-.201.121-.006.003-.011.008-.018.011l-13.29 4.798a1.21 1.21 0 01-1.267-.279 1.192 1.192 0 01-.282-1.258l4.833-13.195c.003-.007.009-.012.011-.019.03-.075.084-.138.129-.208.043-.065.075-.138.129-.192.005-.006.007-.014.012-.019L61.71 2.26c.003-.004.008-.005.012-.009.004-.003.005-.008.009-.012C62.776 1.25 64.626.68 66.537.727c2.036.06 3.809.787 4.993 2.047 2.597 2.748 2.355 7.292.402 9.518zm-2.167-7.879c-.732-.779-1.934-1.248-3.3-1.288-1.446-.033-2.584.395-3.066.849l-33.622 33.38-3.857 10.53 10.606-3.83 33.592-33.349c1.081-1.247 1.41-4.427-.353-6.292zm-28.949 8.291H3.36v57.578h57.998l-.003-37.17c0-.663.541-1.2 1.209-1.2.666 0 1.208.537 1.208 1.2l.002 38.37a1.206 1.206 0 01-1.208 1.199H2.152a1.204 1.204 0 01-1.209-1.199V11.505c0-.663.541-1.2 1.209-1.2h38.664c.667 0 1.208.537 1.208 1.2 0 .662-.541 1.199-1.208 1.199z'
      />
    </svg>
  )
}

export default Notepad
