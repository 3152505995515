// img home
import uadImgHome from 'img/data/projects/uad/home-preview.png'
// import labonneboxImgHome from 'img/data/projects/bonnebox/bonne-box.jpg'

// img small (portfolio)
import uadImgSmall from 'img/data/mini-folio/lusine-a-design.jpg'
// import labonneboxImgSmall from 'img/data/mini-folio/la-bonne-box.jpg'
import nycityguideImgSmall from 'img/data/mini-folio/new-york-city-guide.jpg'
import drawings2012ImgSmall from 'img/data/mini-folio/2012-drawings.jpg'
import patientImgSmall from 'img/data/mini-folio/patient-interactive-video-small.jpg'
import contentFactoryImgSmall from 'img/data/mini-folio/content-factory-small.jpg'
import riskRouletteImgSmall from 'img/data/mini-folio/risk-roulette-small.jpg'
// import interactiveGuidelinesImgSmall from 'img/data/mini-folio/interactive-guidelines-small.jpg'
// import medicalNewsImgSmall from 'img/data/mini-folio/medical-news-small.jpg'
// import boredTeenImgSmall from 'img/data/mini-folio/bored-teenager-campaign-small.jpg'
// import gamifiedEdetailImgSmall from 'img/data/mini-folio/gamified-e-detail-small.jpg'
import modularBrandImgSmall from 'img/data/mini-folio/modular-brand-experience-small.jpg'
import myResumeFr from 'pdf/berenice-krzyzaniak-boudot-cv.pdf'
import myResumeEn from 'pdf/berenice-krzyzaniak-boudot-resume.pdf'

const social = {
  linkedin: {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/berenicek',
    isExternal: true
  },
  pinterest: {
    label: 'Pinterest',
    url: 'https://www.pinterest.fr/berenicek',
    isExternal: true
  },
  instagram: {
    label: 'Instagram',
    url: 'http://instagram.com/sweetberenicek',
    isExternal: true
  }
}

const tree = {
  portfolio: {
    label: 'Portfolio',
    url: '/portfolio',
    children: {
      patient: {
        label: 'Patient interactive video',
        desc: 'How do we create a dynamic interactive experience to raise physician’s awareness on the signs of uncontrolled asthma ?',
        hashtags: '#multichannel #campaign',
        url: '/portfolio/patient-interactive-video',
        imgSmall: patientImgSmall
      },
      contentfactory: {
        label: 'Content Factory',
        desc: 'description',
        hashtags: '#atomic #global #strategy',
        url: '/portfolio/content-factory',
        imgSmall: contentFactoryImgSmall
      },
      riskroulette: {
        label: 'Risk Roulette',
        desc: 'description',
        hashtags: '#digital #experience #eDetail ',
        url: '/portfolio/risk-roulette',
        imgSmall: riskRouletteImgSmall
      },
      modularbrand: {
        label: 'Modular brand experience',
        desc: 'description',
        hashtags: '#eyeforpharmaaward2019',
        url: '/portfolio/modular-brand-experience',
        imgSmall: modularBrandImgSmall
      },
      uad: {
        label: 'Personalization challenge',
        labelMiniFolio: 'L\'Usine à Design e-commerce',
        desc: 'Identité, conception &amp; interface pour une site de mobilier et déco.',
        imgSmall: uadImgSmall,
        imgHome: uadImgHome,
        url: '/portfolio/usine-a-design'
      }
      // interactiveguidelines: {
      //   label: 'Interactive guidelines',
      //   desc: 'description',
      //   hashtags: '#eyeforpharmaaward2019',
      //   url: '/portfolio/interactive-guideline',
      //   imgSmall: interactiveGuidelinesImgSmall
      // },
      // medicalnewsplatform: {
      //   label: 'Medical news platform',
      //   desc: 'description',
      //   hashtags: '#HCPs #content #video',
      //   url: '/portfolio/medical-news-platform',
      //   imgSmall: medicalNewsImgSmall
      // },
      // boredteen: {
      //   label: 'Bored teenager campaign',
      //   desc: 'description',
      //   hashtags: '#campaign #digital #local',
      //   url: '/portfolio/bored-teenager-campaign',
      //   imgSmall: boredTeenImgSmall
      // },
      // gamifiededetail: {
      //   label: 'Gamified e-detail',
      //   desc: 'description',
      //   hashtags: '#digital #experience #eDetail',
      //   url: '/portfolio/gamified-e-detail',
      //   imgSmall: gamifiedEdetailImgSmall
      // },
      // bonnebox: {
      //   label: 'La Bonne Box',
      //   labelMiniFolio: 'La Bonne Box e-commerce',
      //   desc: 'Refonte d’identité web &amp; interface pour une box d’épicerie fine.',
      //   imgSmall: labonneboxImgSmall,
      //   imgHome: labonneboxImgHome,
      //   url: '/portfolio/bonne-box'
      // }
    }
  },
  contact: {
    label: 'CV & contact',
    url: '/contact',
    children: {
      cvFr: {
        label: 'Voilà mon CV',
        url: myResumeFr,
        isExternal: true
      },
      cvEn: {
        label: 'Here\'s my resume',
        url: myResumeEn,
        isExternal: true
      },
      contactme: {
        label: 'Contact me',
        url: '/contact'
      }
    }
  },
  drawings: {
    label: 'Drawings',
    url: '/drawings',
    children: {
      nycityguide: {
        label: 'My NY city guide',
        desc: 'Carnet de voyage &amp; d\'organisation',
        url: '/drawings/ny-city-guide',
        imgSmall: nycityguideImgSmall
      },
      year2013: {
        label: '2013',
        url: '/drawings/2013'
      },
      year2012: {
        label: '2012',
        desc: 'Carnet de croquis et inspirations',
        imgSmall: drawings2012ImgSmall,
        url: '/drawings/2012'
      }
    }
  }
}

const miniPortfolio = [
  tree.portfolio.children.patient,
  tree.portfolio.children.contentfactory,
  tree.portfolio.children.riskroulette,
  // tree.portfolio.children.interactiveguidelines,
  // tree.portfolio.children.medicalnewsplatform,
  // tree.portfolio.children.boredteen,
  // tree.portfolio.children.gamifiededetail,
  tree.portfolio.children.modularbrand,
  tree.portfolio.children.uad
  // tree.portfolio.children.bonnebox
]

export default tree
export { social, miniPortfolio }
