import { useEffect } from 'react'
import styled from '@emotion/styled'

import tree from 'tree'
import {
  TitleCaps1,
  TitleCaps2,
  TitleCaps3,
  Label,
  TitleMain1,
  Text,
  TitleMain3,
  Separator,
  HighlightPink,
  HighlightYellow,
  Hashtags
} from 'components/Styleguide'
import TellMe from 'components/TellMe'

// images
import image1 from './img/image1.png'
import approach from './img/approach.png'
import process from './img/process.png'

// video
import videoSrc from 'video/Broccoli.mp4'

// components
import {
  ImgWithBorder,
  DoublePart,
  Part,
  TitleWithLabel,
  GrayBox,
  BlockPlayer,
  Box,
  PlayerWrapper,
  Player,
  Process,
  ProcessText,
  ProcessLayout,
  Img2
} from 'components/common'
import { pageTitle } from 'index'
import ButtonNextProject from 'components/ButtonNextProject'

const project = tree.portfolio.children.contentfactory

// CO-CREATION

const ImgApproach = styled.img`
  width: 100%;
  max-width: calc(1236px / 2);
  display: block;
  margin: 0 auto;
`

const CreativeDirection = styled.div`
  padding-top: 40px;
  padding-bottom: 70px;
`

const VideoContainer = styled.div`
  margin: 0 auto;
  max-width: 500px;
`

const ContentFactory = () => {
  useEffect(() => {
    pageTitle(project.label)
  }, [])

  return (
    <div className='page-project'>
      <TitleCaps1 mb={20}>
        as creative director for aptus health, healthcare agency
      </TitleCaps1>

      <TitleWithLabel>
        <Label>
          Case study
        </Label>
        <TitleMain1>
          {project.label}
        </TitleMain1>
      </TitleWithLabel>
      <Hashtags>
        #digital #health #content #atomic #creation #process #optimization #global #strategy #contentfactory #video #socialmedia
      </Hashtags>

      <ImgWithBorder distance='8.4%' width={892}>
        <img src={image1} alt='' />
      </ImgWithBorder>

      <DoublePart>
        <Part>
          <TitleCaps2 mb={10}>
            The context
          </TitleCaps2>
          <Text small>
            In 2018, a big pharma consulted us about their needs for innovation around content creation and medical content strategy for a quick-to-market deployment.
          </Text>
        </Part>
        <Part>
          <TitleCaps2 mb={10}>
            The audience
          </TitleCaps2>
          <Text small>
            Local markets in need of new, relevant & digestible contents communicating with local HCPs.
          </Text>
        </Part>
      </DoublePart>

      <Separator />

      {/* CHALLENGE */}
      <Box mt={40} mb={40}>
        <TitleCaps3>
          The challenge
        </TitleCaps3>
        <TitleMain3>
          How do you engage physicians around the world with a content strategy relevant to their needs while optimizing the creation processes, production costs & client’s touchpoints  ?
        </TitleMain3>
      </Box>

      <Separator />

      {/* APPROACH */}
      <GrayBox>
        <TitleCaps2 mb={10}>
          Our approach
        </TitleCaps2>
        <Text mb={60}>
          After auditing local client needs, production and project management set-up, we developped a series of templated to create content ranging from easy-to-digest clinical essentials to sophisticated creative videos. All content was designed to provide the latest news to local HCPs.
        </Text>

        <ImgApproach src={approach} alt='' />
      </GrayBox>

      <Separator />

      {/* PROCESS ATOMIZATION */}
      <Process>
        <ProcessLayout>
          <ProcessText>
            <TitleCaps2 mb={30} left>
              Process atomization
            </TitleCaps2>

            <Text left>
              By <HighlightPink>atomizing the process</HighlightPink> of any piece of content creation, we were able to detect and simplify the completion of each step in order to make it <HighlightYellow>quick to create</HighlightYellow> and approve, <HighlightPink>adaptable to variations of media</HighlightPink> (video, responsive newsletter...) all within high aesthetic standards.
              <br /><br />
              Our <HighlightPink>atomized standards for infographics</HighlightPink> resulted in a quick-to market solution with <HighlightYellow>a maximum of 3 weeks</HighlightYellow> between the choice of clinical paper to the approval and delivery to the global hub. The resulting infographic was adapted for different use : PDF download, responsive newsletter and social media video.
            </Text>
          </ProcessText>
          <Img2 width={977}>
            <img src={process} alt='' />
          </Img2>
        </ProcessLayout>
      </Process>

      <Separator />

      {/* CREATIVE DIRECTION */}
      <CreativeDirection>
        <TitleCaps3>
          Creative direction - imagining new workflows for effective deliveries
        </TitleCaps3>
        <TitleMain3>
          The result was a production of over 200 high-quality and quick-to-market assets produced per year adapted for 20 countries and covering 6 disease areas.
        </TitleMain3>
      </CreativeDirection>

      <Separator />

      {/* VIDEO */}
      <BlockPlayer>
        <TitleCaps2 mb={10}>
          The irreverent social video series
        </TitleCaps2>
        <Text mb={30}>
          After a strong 2 years partnership, our client’s hypertension team came to us with a request to develop a series of 4 short videos for social media to raise awareness on the continuing problem of high blood pressure directed to HCPs and global population.
        </Text>

        <VideoContainer>
          <PlayerWrapper ratio='80%' margin='60px'>
            <Player
              url={videoSrc}
              controls
              volume={0.5}
              width='100%'
              height='100%'
            />
          </PlayerWrapper>
        </VideoContainer>
      </BlockPlayer>

      <Separator />

      <ButtonNextProject project={project} />

      <TellMe>
        Let's talk about your brand
      </TellMe>
    </div>
  )
}

export default ContentFactory
