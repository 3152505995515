import imgPhotoBere from 'img/data/contact/photo-bere.jpg'
import { TitleCaps4, TitleCaps2, TitleMain2 } from 'components/Styleguide'
import tree from 'tree'
import styled from '@emotion/styled'
import TellMe from 'components/TellMe'

const Container = styled.div`
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`
const TitleCaps2Styled = styled(TitleCaps2)`
  color: ${({ theme }) => theme.colors.text};
  margin-top: 15px;
  letter-spacing: 2px;
`

const Title1 = styled(TitleMain2)`
  margin-top: -50px;
  @media (max-width: 768px) {
    margin-top: -30px;
  }
`

const Title2 = styled(TitleCaps2Styled)`
  text-align: right;
  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 20px;
  }
`

const Title3 = styled(TitleMain2)`
  margin-top: -15px;
  text-align: left;
  padding-left: 40px;
  @media (max-width: 768px) {
    text-align: center;
    padding-left: 0;
  }
`

const Photo = styled.div`
  img {
    display: block;
    margin: 0 auto;
  }
`

const TitleCaps4Styled = styled(TitleCaps4)`
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 40px;
  letter-spacing: 2px;
  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.text};
  }
  a:hover {
    color: ${({ theme }) => theme.colors.dark};
  }
`

function Contact () {
  return (
    <Container>
      {/* PHOTO */}
      <Photo>
        <img src={imgPhotoBere} alt='Bérénice Krzyzaniak-Boudot' width={250} />
      </Photo>

      <Title1>
        I am looking for an adventure,
      </Title1>

      <Title2>
        Je suis à l’écoute de nouvelles opportunités
      </Title2>

      <Title3>
        let's talk about your next big move!
      </Title3>

      <TitleCaps2Styled>
        parlons de votre projet
      </TitleCaps2Styled>

      {/* BUTTON CONTACT */}
      <TellMe url='mailto:berenice.krzyzaniak@gmail.com' regularLink>
        Leave me a message
      </TellMe>

      {/* CV */}
      <TitleCaps4Styled>
        <a href={tree.contact.children.cvEn.url} target='_blank' rel='noreferrer'>
          {tree.contact.children.cvEn.label}
        </a>
        {' '}/{' '}
        <a href={tree.contact.children.cvFr.url} target='_blank' rel='noreferrer'>
          {tree.contact.children.cvFr.label}
        </a>
      </TitleCaps4Styled>
    </Container>
  )
}

export default Contact
