const theme = {
  space: {
    xsmall: 10,
    small: 20,
    medium: 40,
    large: 60,
    xlarge: 80
  },
  colors: {
    black: '#010101', // gros titre
    dark: '#080808', // titres et textes
    text: '#a2a5a4', // texte en gris
    separator: '#d9d9d9', // separator
    grayMenu: '#e6e6e6', // bouton menu gris
    grayBg: '#f3f3f3', // gris en fond
    yellow: '#fff6b0', // highlight
    pink: '#ffe6ef', // highlight
    blue: '#e3fbfd' // highlight
  }
}

export default theme
