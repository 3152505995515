import React from 'react'
import styled from '@emotion/styled'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import { Link } from 'react-router-dom'

// hooks
import useMobile from 'hooks/useIsMobile'

// tree
import tree from 'tree'

// components
import MiniPortfolio from 'components/MiniPortfolio'
import { Text, TitleMain2, TitleMain4, TitleCaps4, HighlightPink, HighlightYellow, HighlightBlue, Separator, TitleCaps2 } from 'components/Styleguide'
import TellMe from 'components/TellMe'
import { Box, Img2, ImgWithBorder } from 'components/common'

// images
import imgRiskRoulette from 'pages/Portfolio/RiskRoulette/img/image1.png'
import triangleMains from './img/triangle-mains.png'
import trianglePediatrie from './img/triangle-pediatrie.png'

const Intro = styled.div`
  display: flex;
  margin-top: 30px;
  padding-right: 70px;
  height: 45vh;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    padding-right: 0;
    height: auto;
  }
`

const TrianglesContainer = styled('div')`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 940px;
  left: 50%;
  transform: translateX(-50%);
`
const Triangle = styled(motion.div)`
  position: absolute;
`

const Img = styled('img')`
  width: calc(369px / 2);
  display: block;
`

const TitleMain4Styled = styled(TitleMain4)`
  z-index: 2;
  position: relative;
`
const TitleCaps4Styled = styled(TitleCaps4)`
  margin-bottom: 150px;
  @media (max-width: 768px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`

const Triangles = () => {
  const { scrollYProgress } = useViewportScroll()
  const yMains = useTransform(scrollYProgress, [0, 0.5], ['47vh', '130vh'])
  const yPediatrie = useTransform(scrollYProgress, [0, 0.5], ['12vh', '130vh'])

  return (
    <TrianglesContainer>
      <Triangle style={{ left: -90, bottom: yMains }}>
        <Img src={triangleMains} />
      </Triangle>
      <Triangle style={{ right: 60, bottom: yPediatrie, zIndex: 1 }}>
        <Img src={trianglePediatrie} />
      </Triangle>
    </TrianglesContainer>
  )
}

function Portfolio () {
  const isMobile = useMobile()
  return (
    <>
      {!isMobile && <Triangles />}
      <div style={{ position: 'relative', zIndex: 0 }}>
        <Intro>
          <TitleMain2 left mb={35}>
            Hello! I’m Bérénice Krzyzaniak,<br />
            creative director in healthcare communications.
          </TitleMain2>

          <Text left>
            <HighlightPink>Digital marketing expert,</HighlightPink> I have a pragmatic approach to design. I am passionate about finding innovative brand strategies and empowering creative teams <HighlightBlue>to deliver beautiful and meaningful digital experiences</HighlightBlue>. I have worked for over 15 years’ in the digital industry across e-commerce and healthcare, and rolled out high-quality end-to-end multi-channel campaigns. I bring a <HighlightYellow>fresh vision to healthcare challenges.</HighlightYellow>
          </Text>
        </Intro>

        <TitleCaps4Styled left>
          <Link to={tree.portfolio.children.riskroulette.url}>
            Discover my latest case study &gt;
          </Link>
        </TitleCaps4Styled>

        <Separator mb={20} />

        <Link to={tree.portfolio.children.riskroulette.url} className='project'>
          <p className='bt-contact'>
            <span className='bt'>
              Learn more
            </span>
          </p>
          <Box className='inner'>
            <TitleCaps2 mt={15} mb={10}>
              {tree.portfolio.children.riskroulette.label}
            </TitleCaps2>
            <Text small mb={30}>
              #digital #experience #e-detail  #branded  #multichannel #campaign #strategy #creativedirection #UI #Artdirection
            </Text>
            <Img2 width={1603}>
              <img src={imgRiskRoulette} alt='' />
            </Img2>
          </Box>
        </Link>

        <Separator mb={40} mt={25} />

        {/* MINI PORTFOLIO */}
        <MiniPortfolio />

        <ImgWithBorder distance='74%' after mb={0}>
          <TitleMain4Styled>
            I’m passionnate about thinking brand strategies, crafting interactive journeys, playing with digital best practices and leading teams to excellence.
          </TitleMain4Styled>
        </ImgWithBorder>

        <TellMe>
          Let's talk about your brand
        </TellMe>
      </div>
    </>
  )
}

export default Portfolio
